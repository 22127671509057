import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from '@/models/report/interfaces';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { utils, write } from 'xlsx';
import { numberToAbsStringOrEmpty } from '@/helpers/common';
import {
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_MINI,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_MINI,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateCalculationViewReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const head = [
    [
      translate('labels.zip'),
      translate('headers.deliveryorder'),
      translate('headers.name'),
      '',
      translate('headers.basetime'),
      translate('headers.sumtime'),
      translate('headers.unit'),
      translate('headers.amount'),
      translate('headers.amountadjustment'),
      translate('headers.amountprunit'),
      translate('headers.unitfororder'),
      translate('headers.price'),
      translate('headers.unitprice'),
      translate('headers.total'),
    ],
  ];
  const bEdata: (string | number)[][] = [];
  const buildingElementBreakPoints: Array<number> = [];

  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    buildingElementBreakPoints.push(bEdata.length);
    const row = [
      !options.IncludeBuildingItems ? postnr++ : '',
      '',
      ele.Name ?? '',
      '',
      '',
      numberToAbsStringOrEmpty(ele.SumTime.value, 1),
      ele.Unit?.value ?? '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      '',
      '',
      '',
      numberToAbsStringOrEmpty(ele.Price.value, 1),
      numberToAbsStringOrEmpty(ele.UnitPrice?.value, 1),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 0),
    ];

    bEdata.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          item.DeliveryOrder ?? '',
          item.Name ?? '',
          item.PreCutText.value ?? '',
          item.BaseTime ?? '',
          numberToAbsStringOrEmpty(item.SumTime.value, 1),
          item.Unit ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          item.AmountAdjustment ?? '',
          item.AmountPrUnit ?? '',
          item.UnitForOrder ?? '',
          numberToAbsStringOrEmpty(item.Price, 1),
          numberToAbsStringOrEmpty(item.UnitPriceWithAdditions?.value, 1),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 0),
        ];
        bEdata.push(row);
      });
    }
  });

  const reportName = `${translate('reports.calculation-view-report')}`;

  doc.text(reportName, 14, first.finalY + 9);

  autoTable(doc, {
    startY: first.finalY + 14,
    head: head,
    body: bEdata,
    showHead: 'firstPage',
    headStyles: { fillColor: '#263238', valign: 'middle' },
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
    },
    didParseCell(data) {
      if (
        buildingElementBreakPoints.includes(data.row.index) &&
        data.section === 'body' &&
        options.IncludeBuildingItems
      ) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.fillColor = '#d6d8da';
      }
    },
    columnStyles: {
      0: {
        valign: 'middle',
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
      },
      3: {
        valign: 'middle',
        textColor: '#b71c1c',
      },
      4: {
        valign: 'middle',
      },
      5: {
        valign: 'middle',
      },
      6: {
        valign: 'middle',
      },
      7: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      8: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      9: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      10: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 15,
      },
      11: {
        valign: 'middle',
        halign: 'right',
      },
      12: {
        valign: 'middle',
        halign: 'right',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      13: {
        valign: 'middle',
        halign: 'right',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }
  await doc.save(pdfName);
};

export const generateCalculationViewReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (!projectCalculation.value) return;

  const wb = utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.calculation-view-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const bEdata: (string | number)[][] = [];

  bEdata.push([sheetName]);
  bEdata.push(['']);

  await createHeaderXlsx(bEdata);
  await createProjectInfoXlsx(bEdata);

  bEdata.push([
    `${translate('labels.zip')}`,
    `${translate('headers.deliveryorder')}`,
    `${translate('headers.name')}`,
    '',
    `${translate('headers.basetime')}`,
    `${translate('headers.sumtime')}`,
    `${translate('headers.unit')}`,
    `${translate('headers.amount')}`,
    `${translate('headers.amountadjustment')}`,
    `${translate('headers.amountprunit')}`,
    `${translate('headers.unitfororder')}`,
    `${translate('headers.price')}`,
    `${translate('headers.unitprice')}`,
    `${translate('headers.total')}`,
  ]);

  const buildingElementBreakPoints: Array<number> = [];

  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    buildingElementBreakPoints.push(bEdata.length);
    const row = [
      !options.IncludeBuildingItems ? postnr++ : '',
      '',
      ele.Name ?? '',
      '',
      '',
      numberToAbsStringOrEmpty(ele.SumTime.value, 1),
      ele.Unit?.value ?? '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      '',
      '',
      '',
      numberToAbsStringOrEmpty(ele.Price.value, 1),
      numberToAbsStringOrEmpty(ele.UnitPrice?.value, 1),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 0),
    ];

    bEdata.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          item.DeliveryOrder ?? '',
          item.Name ?? '',
          item.PreCutText.value ?? '',
          item.BaseTime ?? '',
          numberToAbsStringOrEmpty(item.SumTime.value, 1),
          item.Unit ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          item.AmountAdjustment ?? '',
          item.AmountPrUnit ?? '',
          item.UnitForOrder ?? '',
          numberToAbsStringOrEmpty(item.Price, 1),
          numberToAbsStringOrEmpty(item.UnitPriceWithAdditions?.value, 1),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 0),
        ];
        bEdata.push(row);
      });
    }
  });

  const ws = utils.aoa_to_sheet(bEdata);
  wb.Sheets[sheetName] = ws;

  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generateCalculationViewReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateCalculationViewReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateCalculationViewReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
