import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from '@/models/report/interfaces';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { utils, write } from 'xlsx';
import { numberToAbsStringOrEmpty } from '@/helpers/common';
import {
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_MINI,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_MINI,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateEconomyViewReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const head = [
    [
      `${translate('labels.zip')}`,
      `${translate('headers.sumtime')}`,
      `${translate('headers.factor2')}`,
      `${translate('headers.sumsalaryandsocialcost')}`,
      `${translate('headers.name')}`,
      '',
      `${translate('headers.amount')}`,
      `${translate('headers.unitfororder')}`,
      `${translate('headers.summaterialsue')}`,
      `${translate('headers.projectcost')}`,
      `${translate('headers.factor4')}`,
      `${translate('headers.factor6')}`,
      `${translate('headers.factor7')}`,
      `${translate('headers.summargin')}`,
      `${translate('headers.sumtotal')}`,
    ],
  ];
  const bEdata: (string | number)[][] = [];
  const buildingElementBreakPoints: Array<number> = [];

  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    buildingElementBreakPoints.push(bEdata.length);
    const row = [
      !options.IncludeBuildingItems ? postnr++ : '',
      numberToAbsStringOrEmpty(ele.SumTime.value, 0),
      numberToAbsStringOrEmpty(ele.Factor2.value, 0),
      numberToAbsStringOrEmpty(ele.SumSalaryAndSocialCost.value, 0),
      ele.Name ?? '',
      '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      '',
      numberToAbsStringOrEmpty(ele.SumMaterialsUE.value, 0),
      numberToAbsStringOrEmpty(ele.ProjectCost.value, 0),
      numberToAbsStringOrEmpty(ele.Factor4.value, 0),
      numberToAbsStringOrEmpty(ele.Factor6.value, 0),
      numberToAbsStringOrEmpty(ele.Factor7.value, 0),
      numberToAbsStringOrEmpty(ele.SumMargin.value, 0),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 0),
    ];

    bEdata.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          numberToAbsStringOrEmpty(item.SumTime.value, 0),
          numberToAbsStringOrEmpty(item.Factor2.value, 0),
          numberToAbsStringOrEmpty(item.SumSalaryAndSocialCost.value, 0),
          item.Name ?? '',
          item.PreCutText.value ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          item.UnitForOrder ?? '',
          numberToAbsStringOrEmpty(item.SumMaterialsUE.value, 0),
          numberToAbsStringOrEmpty(item.ProjectCost.value, 0),
          numberToAbsStringOrEmpty(item.Factor4.value, 0),
          numberToAbsStringOrEmpty(item.Factor6.value, 0),
          numberToAbsStringOrEmpty(item.Factor7.value, 0),
          numberToAbsStringOrEmpty(item.SumMargin.value, 0),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 0),
        ];
        bEdata.push(row);
      });
    }
  });

  const reportName = `${translate('reports.economy-view-report')}`;

  doc.text(reportName, 14, first.finalY + 9);

  autoTable(doc, {
    startY: first.finalY + 14,
    head: head,
    body: bEdata,
    showHead: 'firstPage',
    headStyles: { fillColor: '#263238', valign: 'middle' },
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_MINI : LARGE_TEXT_MINI,
    },
    didParseCell(data) {
      if (
        buildingElementBreakPoints.includes(data.row.index) &&
        data.section === 'body' &&
        options.IncludeBuildingItems
      ) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.fillColor = '#d6d8da';
      }
    },
    columnStyles: {
      0: {
        valign: 'middle',
        cellWidth: 8,
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      3: {
        valign: 'middle',
      },
      4: {
        valign: 'middle',
      },
      5: {
        valign: 'middle',
        textColor: '#b71c1c',
      },
      6: {
        valign: 'middle',
      },
      7: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      8: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      9: {
        valign: 'middle',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      10: {
        valign: 'middle',
        halign: 'right',
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_MEDIUM
            : LARGE_TEXT_MEDIUM,
      },
      11: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 15,
      },
      12: {
        valign: 'middle',
        halign: 'right',
      },
      13: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 15,
      },
      14: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 15,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateEconomyViewReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (!projectCalculation.value) return;

  const wb = utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.economy-view-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const bEdata: (string | number)[][] = [];

  bEdata.push([sheetName]);
  bEdata.push(['']);

  await createHeaderXlsx(bEdata);
  await createProjectInfoXlsx(bEdata);

  bEdata.push([
    `${translate('labels.zip')}`,
    `${translate('headers.sumtime')}`,
    `${translate('headers.factor2')}`,
    `${translate('headers.sumsalaryandsocialcost')}`,
    `${translate('headers.name')}`,
    `${translate('headers.precut')}`,
    `${translate('headers.amount')}`,
    `${translate('headers.unitfororder')}`,
    `${translate('headers.summaterialsue')}`,
    `${translate('headers.projectcost')}`,
    `${translate('headers.factor4')}`,
    `${translate('headers.factor6')}`,
    `${translate('headers.factor7')}`,
    `${translate('headers.summargin')}`,
    `${translate('headers.sumtotal')}`,
  ]);

  const buildingElementBreakPoints: Array<number> = [];

  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    buildingElementBreakPoints.push(bEdata.length);
    const row = [
      !options.IncludeBuildingItems ? postnr++ : '',
      numberToAbsStringOrEmpty(ele.SumTime.value, 0),
      numberToAbsStringOrEmpty(ele.Factor2.value, 0),
      numberToAbsStringOrEmpty(ele.SumSalaryAndSocialCost.value, 0),
      ele.Name ?? '',
      '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      '',
      numberToAbsStringOrEmpty(ele.SumMaterialsUE.value, 0),
      numberToAbsStringOrEmpty(ele.ProjectCost.value, 0),
      numberToAbsStringOrEmpty(ele.Factor4.value, 0),
      numberToAbsStringOrEmpty(ele.Factor6.value, 0),
      numberToAbsStringOrEmpty(ele.Factor7.value, 0),
      numberToAbsStringOrEmpty(ele.SumMargin.value, 0),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 0),
    ];

    bEdata.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          numberToAbsStringOrEmpty(item.SumTime.value, 0),
          numberToAbsStringOrEmpty(item.Factor2.value, 0),
          numberToAbsStringOrEmpty(item.SumSalaryAndSocialCost.value, 0),
          item.Name ?? '',
          item.PreCutText.value ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          item.UnitForOrder ?? '',
          numberToAbsStringOrEmpty(item.SumMaterialsUE.value, 0),
          numberToAbsStringOrEmpty(item.ProjectCost.value, 0),
          numberToAbsStringOrEmpty(item.Factor4.value, 0),
          numberToAbsStringOrEmpty(item.Factor6.value, 0),
          numberToAbsStringOrEmpty(item.Factor7.value, 0),
          numberToAbsStringOrEmpty(item.SumMargin.value, 0),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 0),
        ];
        bEdata.push(row);
      });
    }
  });

  const ws = utils.aoa_to_sheet(bEdata);
  wb.Sheets[sheetName] = ws;

  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generateEconomyViewReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateEconomyViewReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateEconomyViewReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
