import { createReportName, downloadExcel } from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from '@/models/report/interfaces';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportFileType, ReportType } from '@/models/report/enums';
import { utils, write } from 'xlsx';
import { numberToAbsStringOrEmpty } from '@/helpers/common';
import { DEFAULT_TEXT_XSMALL, LARGE_TEXT_XSMALL } from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateBuildingElementReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();

  if (!projectCalculation.value) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);

  const pdfName = createReportName(type, ReportFileType.PDF);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;

  const head = [
    [
      translate('labels.zip'),
      translate('labels.production-line'),
      translate('labels.description'),
      translate('labels.unit'),
      translate('labels.amount'),
      translate('labels.unit-price'),
      translate('labels.sum'),
    ],
  ];
  const bEdata: (string | number)[][] = [];
  const buildingElementBreakPoints: Array<number> = [];

  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    buildingElementBreakPoints.push(bEdata.length);
    const row = [
      !options.IncludeBuildingItems ? postnr++ : '',
      ele.ProductionLine ?? '',
      ele.Name ?? '',
      ele.Unit?.value ?? '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      numberToAbsStringOrEmpty(ele.UnitPrice?.value, 2),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
    ];
    bEdata.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          '',
          item.Name ?? '',
          item.Unit ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          numberToAbsStringOrEmpty(item.UnitPriceWithAdditions?.value, 2),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
        ];
        bEdata.push(row);
      });
    }
  });

  const MVA = 0.25;
  let sumExclMva = 0;
  let sumInclMva = 0;
  let MvaOnly = 0;

  elements.forEach(ele => {
    sumExclMva += ele.SumTotal.value ?? 0;
  });

  MvaOnly = sumExclMva * MVA;
  sumInclMva = sumExclMva + MvaOnly;

  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    numberToAbsStringOrEmpty(sumExclMva, 2),
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    numberToAbsStringOrEmpty(MvaOnly, 2),
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    numberToAbsStringOrEmpty(sumInclMva, 2),
  ];

  bEdata.push(sumExclMvaRow);
  bEdata.push(mvaRow);
  bEdata.push(sumInclMvaRow);

  const reportName = options.IncludeBuildingItems
    ? `${translate('reports.building-elements-include-bi-report')}`
    : `${translate('reports.building-elements-report')}`;

  doc.text(reportName, 14, first.finalY + 9);

  autoTable(doc, {
    startY: first.finalY + 14,
    head: head,
    body: bEdata,
    showHead: 'firstPage',
    headStyles: { fillColor: '#263238' },
    styles: {
      fontSize:
        options.FontSize === 'default'
          ? DEFAULT_TEXT_XSMALL
          : LARGE_TEXT_XSMALL,
    },
    didParseCell(data) {
      if (
        buildingElementBreakPoints.includes(data.row.index) &&
        data.section === 'body' &&
        options.IncludeBuildingItems
      ) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.fillColor = '#d6d8da';
      } else if (
        (data.section === 'body' && data.row.index === bEdata.length - 1) ||
        data.row.index === bEdata.length - 2 ||
        data.row.index === bEdata.length - 3
      ) {
        data.row.cells[0].styles.fillColor = 'white';
        data.row.cells[1].styles.fillColor = 'white';
        data.row.cells[2].styles.fillColor = 'white';
        data.row.cells[3].styles.fillColor = 'white';
        data.row.cells[4].styles.fillColor = 'white';

        data.row.cells[5].styles.fontStyle = 'bold';
        data.row.cells[5].styles.cellPadding = 0.7;
        data.row.cells[5].styles.fillColor = '#f7f7f7';

        data.row.cells[6].styles.fontStyle = 'bold';
        data.row.cells[6].styles.cellPadding = 0.7;
        data.row.cells[6].styles.fillColor = '#f7f7f7';
      } else if (data.section === 'body' && options.IncludeBuildingItems) {
        data.cell.styles.fillColor = 'white';
      }
    },
    columnStyles: {
      0: {
        valign: 'middle',
      },
      1: {
        valign: 'middle',
      },
      2: {
        valign: 'middle',
      },
      3: {
        valign: 'middle',
      },
      4: {
        valign: 'middle',
      },
      5: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
      6: {
        valign: 'middle',
        halign: 'right',
        cellWidth: 20,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(10); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateBuildingElementReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { projectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (!projectCalculation.value) return;

  const wb = utils.book_new();

  const xlsxName = createReportName(type, ReportFileType.XLSX);

  const sheetName = checkAndbuildSheetName(
    projectCalculation.value.Name,
    wb.SheetNames
  );

  wb.Props = {
    Title: `${translate('reports.material-order-report')}`,
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(sheetName);

  const data: (string | number)[][] = [];

  data.push([sheetName]);
  data.push(['']);

  await createHeaderXlsx(data);
  await createProjectInfoXlsx(data);

  data.push([
    `${translate('labels.zip')}`,
    `${translate('labels.production-line')}`,
    `${translate('labels.description')}`,
    `${translate('labels.unit')}`,
    `${translate('labels.amount')}`,
    `${translate('labels.unit-price')}`,
    `${translate('labels.sum')}`,
  ]);
  const elements = projectCalculation.value.BuildingElements;
  let postnr = 1;

  if (!elements) return;

  elements.forEach(ele => {
    const row = [
      '',
      ele.ProductionLine ?? '',
      ele.Name ?? '',
      ele.Unit?.value ?? '',
      numberToAbsStringOrEmpty(ele.Amount, 2),
      numberToAbsStringOrEmpty(ele.UnitPrice?.value, 2),
      numberToAbsStringOrEmpty(ele.SumTotal.value, 2),
    ];
    data.push(row);

    if (options.IncludeBuildingItems) {
      ele.BuildingItems.forEach(item => {
        const row = [
          postnr++,
          '',
          item.Name ?? '',
          item.Unit ?? '',
          numberToAbsStringOrEmpty(item.AmountCalculated.value, 2),
          numberToAbsStringOrEmpty(item.UnitPriceWithAdditions?.value, 2),
          numberToAbsStringOrEmpty(item.SumTotalWithAdditions.value, 2),
        ];
        data.push(row);
      });
    }
  });

  const MVA = 0.25;
  let sumExclMva = 0;
  let sumInclMva = 0;
  let MvaOnly = 0;

  elements.forEach(ele => {
    sumExclMva += ele.SumTotal.value ?? 0;
  });

  MvaOnly = sumExclMva * MVA;
  sumInclMva = sumExclMva + MvaOnly;

  const fillRow = ['', '', '', '', ''];
  const sumExclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-exclude-mva')}`,
    numberToAbsStringOrEmpty(sumExclMva, 2),
  ];
  const mvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-mva-only')}`,
    numberToAbsStringOrEmpty(MvaOnly, 2),
  ];
  const sumInclMvaRow = [
    '',
    '',
    '',
    '',
    '',
    `${translate('labels.sum-include-mva')}`,
    numberToAbsStringOrEmpty(sumInclMva, 2),
  ];

  data.push(fillRow);
  data.push(sumExclMvaRow);
  data.push(mvaRow);
  data.push(sumInclMvaRow);

  const ws = utils.aoa_to_sheet(data);
  wb.Sheets[sheetName] = ws;

  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

  downloadExcel(xlsxName, wbout);
};

export const generateBuildingElementReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateBuildingElementReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateBuildingElementReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
