import { CustomError, Profession } from '@/models/common/interfaces';
import {
  NstEgenskap,
  NstInnholdNSProsjektdata,
  NstNS3459,
  NstPostnrdel,
  NstPostnrdelKode,
  NstPostnrdelType,
  NstProsjektNS,
} from '@/models/standards/ns3459/interfaces';
import {
  ImportedProject,
  Project,
  ProjectOverview,
  ProjectTotals,
} from '@/models/projects/interfaces';
import { reactive, toRefs } from '@vue/composition-api';
import { useProject } from './project';
import { v4 as uuidv4 } from 'uuid';
import { useProjectBuilder } from './project-builder';
import { useSnackbar } from '@/modules/snackbar';
import { useErrorModal } from './error';
import { useApi } from './api';
import { isSuccess } from '@/helpers/common';
import xml2js from 'xml2js';
import { PriceListVM, Vare, VareKategori } from '@/models/pricebook/interfaces';
import { parseOptimeraFloat, parseOptimeraInt } from '@/helpers/parse';
import { utils, read } from 'xlsx';
import { BuildingTypeTemplate } from '@/models/library/interfaces';
import {
  Calculation,
  ProjectConcurrencyToken,
} from '@/models/calculation/interfaces';
import { useCalculation } from './calculation';
import { translate } from '@/localization';

interface State {
  loading: boolean;
  error?: CustomError;
}

const state = reactive<State>({
  loading: false,
  error: undefined,
});

export const useImport = () => {
  const { errorModal } = useErrorModal();
  const { snack } = useSnackbar();

  const getProfessionCodeFromNstProsjektNS = (
    project: NstProsjektNS
  ): string | undefined => {
    let code: string | undefined = undefined;

    if (!project.egenskaperField || project.egenskaperField.length === 0)
      return code;

    const professionCode = project.egenskaperField.find(p => {
      return p.navnField === 'ProfessionCode';
    });

    if (professionCode) {
      code = professionCode.verdiField;
    }

    return code;
  };

  const getProfessionFromNstProsjektNS = async (
    project: NstProsjektNS
  ): Promise<Profession | undefined> => {
    const { getProfessions } = useProject();

    let profession: Profession | undefined = undefined;
    let code = getProfessionCodeFromNstProsjektNS(project);

    if (code) {
      code = code.toString();
      const professions = await getProfessions();
      if (professions) {
        profession = professions.find(p => {
          return p.Code === code;
        });
      }
    }
    return profession;
  };

  const setPropertiesFromXML = (nstn: NstNS3459) => {
    interface ParsedEgenskaper {
      egenskapField: Array<NstEgenskap>;
    }
    interface ParsedPostnrDel {
      postnrdelField: Array<NstPostnrdel>;
    }
    interface ParsedPostnrdelKoderField {
      postnrdelKodeField: Array<NstPostnrdelKode>;
    }
    interface ParsedPostnrdelTyperField {
      postnrdelTypeField: Array<NstPostnrdelType>;
    }

    interface ParsedProject {
      prosjektNSField: Array<NstPostnrdelType>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const iterateDelKode = (obj: Record<string, any>) => {
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (key === 'postnrdelKoderField') {
            if (typeof obj[key]['postnrdelKodeField'] === 'object') {
              obj[key] = [obj[key]['postnrdelKodeField']];
            } else {
              obj[key] = obj[key]['postnrdelKodeField'];
            }
          }
          iterateDelKode(obj[key]);
        }
      });
    };

    try {
      let projects: Array<NstProsjektNS> = [];
      if (!Array.isArray(nstn.prosjekterField.prosjektNSField)) {
        const project = (nstn.prosjekterField
          .prosjektNSField as unknown) as NstProsjektNS;
        projects.push(project);
        nstn.prosjekterField.prosjektNSField = projects;
        projects = nstn.prosjekterField.prosjektNSField;
      } else {
        projects = nstn.prosjekterField.prosjektNSField;
      }

      projects.forEach(proj => {
        const projectProperties = (proj.egenskaperField as unknown) as ParsedEgenskaper;
        const projectProjData = proj.itemField as NstInnholdNSProsjektdata;

        if (projectProjData) {
          projectProjData.postField.forEach(post => {
            const postProperties = (post.egenskaperField as unknown) as ParsedEgenskaper;
            const postPart = (post.postnrdelerField as unknown) as ParsedPostnrDel;

            post.postnrdelerField = postPart?.postnrdelField
              ? postPart.postnrdelField
              : [];
            post.egenskaperField = postProperties?.egenskapField
              ? postProperties.egenskapField
              : [];
          });
        }
        proj.egenskaperField = projectProperties?.egenskapField
          ? projectProperties.egenskapField
          : [];

        if (proj.postnrplanField) {
          const delKoder = (proj.postnrplanField
            .postnrdelKoderField as unknown) as ParsedPostnrdelKoderField;
          const delTyper = (proj.postnrplanField
            .postnrdelTyperField as unknown) as ParsedPostnrdelTyperField;

          proj.postnrplanField.postnrdelKoderField = delKoder?.postnrdelKodeField
            ? delKoder.postnrdelKodeField
            : [];
          proj.postnrplanField.postnrdelTyperField = delTyper?.postnrdelTypeField
            ? delTyper.postnrdelTypeField
            : [];

          if (
            proj.postnrplanField.postnrdelKoderField &&
            proj.postnrplanField.postnrdelKoderField.length > 0
          ) {
            proj.postnrplanField.postnrdelKoderField.forEach(postNrDelKode => {
              iterateDelKode(postNrDelKode);
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const xmlToNstNS3459 = async (
    xml: string
  ): Promise<NstNS3459 | undefined> => {
    let project: NstNS3459 | undefined = undefined;

    const itemObjectList = [
      'avregning',
      'prisforesporsel',
      'pristilbud',
      'prosjektdata',
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseNumbers = (name: any) => {
      if (!isNaN(name) && name.charAt(0) !== '0') {
        name = name % 1 === 0 ? parseInt(name, 10) : parseFloat(name);
      }
      return name;
    };

    project = (await xml2js.parseStringPromise(xml, {
      explicitArray: false,
      explicitRoot: false,
      emptyTag: undefined,
      ignoreAttrs: true,
      tagNameProcessors: [
        xml2js.processors.firstCharLowerCase,
        name => {
          let altName = `${name}Field`;
          if (itemObjectList.includes(name)) {
            altName = 'itemField';
          }
          if (altName === 'iDField') {
            altName = 'idField';
          }
          return altName;
        },
      ],
      valueProcessors: [parseNumbers],
    })) as NstNS3459;

    setPropertiesFromXML(project);

    return project;
  };

  const parseProjectFile = async (
    uploadedFile: File
  ): Promise<NstNS3459 | undefined> => {
    let project: NstNS3459 | undefined = undefined;

    try {
      const data = await new Response(uploadedFile).text();
      if (uploadedFile.type.includes('xml')) {
        project = await xmlToNstNS3459(data);
      } else {
        project = JSON.parse(data) as NstNS3459;
      }
      if (project) {
        if (!project.prosjekterField) {
          snack('snack.file-read-error', false);
        }
        if (!project.genereltField) {
          snack('snack.file-read-error', false);
        }
      } else {
        snack('snack.file-read-error', false);
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return project;
  };

  const parsePriceListCSV = async (
    file: File
  ): Promise<PriceListVM | undefined> => {
    return new Promise(resolve => {
      try {
        const reader = new FileReader();
        let content: string;
        reader.onloadend = evt => {
          try {
            if (evt.target && evt.target.readyState == FileReader.DONE) {
              content = reader.result as string;
              if (content) {
                const lines = content.split(/\r\n|\n/);

                const categories = new Map<string, VareKategori>();

                for (const line of lines) {
                  const lineValues = line.split(';');
                  if (!lineValues || lineValues.length < 17) continue;

                  const groupKey =
                    lineValues[0].trim() != null
                      ? lineValues[0].trim()
                      : lineValues[3].trim();

                  if (!categories.has(groupKey)) {
                    const category: VareKategori = {
                      Number: groupKey,
                      Text: lineValues[3].trim(),
                      Varer: [],
                    };

                    categories.set(groupKey, category);
                  }

                  const category = categories.get(groupKey);

                  const product: Vare = {
                    EAN13ItemNumber: lineValues[1].trim(),
                    ItemNumber: lineValues[2].trim(),
                    ItemText: lineValues[4].trim(),
                    BasePrice: parseOptimeraFloat(lineValues[5].trim()),
                    RecommendedPrice: parseOptimeraFloat(lineValues[6].trim()),
                    UnitDesc: lineValues[7].trim(),
                    ItemText2: lineValues[9].trim(),
                    PackageUnitDesc: lineValues[10].trim(),
                    ItemsInPackage: lineValues[11].trim(),
                    BuyingPrice: parseOptimeraFloat(lineValues[12].trim()),
                    Discount: parseOptimeraFloat(lineValues[13].trim()),
                    ItemNumberNOBB: parseOptimeraInt(lineValues[14].trim()),
                    ItemOwnerNumber: lineValues[15].trim(),
                    ModuleNumber: lineValues[16].trim(),
                  };

                  category?.Varer.push(product);
                }

                const result: PriceListVM = {
                  Categories: Array.from(categories.values()),
                  Name: file.name.replace(/\.[^/.]+$/, ''),
                  ProductCount: lines.length - 1,
                };

                resolve(result);
              }
            }
          } catch (e) {
            console.log(e);
            snack('snack.file-read-error', false);
            resolve(undefined);
          }
        };
        reader.onerror = ex => {
          console.log(ex);
          snack('snack.file-read-error', false);
          resolve(undefined);
        };
        reader.readAsText(file, 'ISO-8859-1');
      } catch (e) {
        console.log(e);
        resolve(undefined);
      }
    });
  };

  const parsePriceListExcel = async (
    file: File
  ): Promise<PriceListVM | undefined> => {
    const getTrimmedExcelStringValue = (
      excelValue: string | null | undefined | number
    ): Nullable<string> => {
      let value = '';

      if (excelValue != null) {
        value = excelValue.toString();
      } else {
        return null;
      }

      return value.trim();
    };

    const parseAsStringOrReturnNumber = (
      excelValue: string | null | undefined | number
    ): Nullable<number> => {
      if (excelValue != null) {
        if (typeof excelValue == 'number' && !isNaN(excelValue)) {
          return Number(excelValue);
        } else {
          return parseOptimeraFloat(excelValue.toString());
        }
      } else {
        return null;
      }
    };

    return new Promise(resolve => {
      try {
        const reader = new FileReader();
        let content: string;
        reader.onloadend = evt => {
          try {
            if (evt.target && evt.target.readyState == FileReader.DONE) {
              content = reader.result as string;
              if (content) {
                const ab: ArrayBufferLike | null | string = evt.target.result;
                if (ab) {
                  const abt: ArrayBufferLike = ab as ArrayBufferLike;
                  const wb = read(new Uint8Array(abt), { type: 'array' });
                  /* Get first worksheet */
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  /* Convert array of arrays */
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const rows: Array<any> = utils.sheet_to_json(ws, {
                    header: 1,
                  });

                  const categories = new Map<string, VareKategori>();

                  rows.shift();

                  for (const row of rows) {
                    if (!row) continue;

                    const groupKey =
                      getTrimmedExcelStringValue(row[0]) != null
                        ? getTrimmedExcelStringValue(row[0])
                        : getTrimmedExcelStringValue(row[3]);

                    if (!groupKey) continue;

                    if (!categories.has(groupKey)) {
                      const category: VareKategori = {
                        Number: groupKey,
                        Text: row[3].toString().trim(),
                        Varer: [],
                      };

                      categories.set(groupKey, category);
                    }

                    const category = categories.get(groupKey);

                    const product: Vare = {
                      EAN13ItemNumber: getTrimmedExcelStringValue(row[1]),
                      ItemNumber: getTrimmedExcelStringValue(row[2]),
                      ItemText: getTrimmedExcelStringValue(row[4]),
                      BasePrice: parseAsStringOrReturnNumber(row[5]),
                      RecommendedPrice: parseAsStringOrReturnNumber(row[6]),
                      UnitDesc: getTrimmedExcelStringValue(row[7]),
                      ItemText2: getTrimmedExcelStringValue(row[9]),
                      PackageUnitDesc: getTrimmedExcelStringValue(row[10]),
                      ItemsInPackage: getTrimmedExcelStringValue(row[11]),
                      BuyingPrice: parseAsStringOrReturnNumber(row[12]),
                      Discount: row[13]
                        ? parseOptimeraFloat(row[13].toString().trim())
                        : null,
                      ItemNumberNOBB: row[14]
                        ? parseOptimeraInt(row[14].toString().trim())
                        : null,
                      ItemOwnerNumber: getTrimmedExcelStringValue(row[15]),
                      ModuleNumber: getTrimmedExcelStringValue(row[16]),
                    };

                    category?.Varer.push(product);
                  }

                  const result: PriceListVM = {
                    Categories: Array.from(categories.values()),
                    Name: file.name.replace(/\.[^/.]+$/, ''),
                    ProductCount:
                      rows.length > 1 ? rows.length - 1 : rows.length,
                  };
                  resolve(result);
                }
              }
            }
          } catch (e) {
            console.log(e);
            snack('snack.file-read-error', false);
            resolve(undefined);
          }
        };
        reader.onerror = ex => {
          console.log(ex);
          snack('snack.file-read-error', false);
          resolve(undefined);
        };
        reader.readAsArrayBuffer(file);
      } catch (e) {
        console.log(e);
        resolve(undefined);
      }
    });
  };

  const importLibrary = async (
    xml: string
  ): Promise<BuildingTypeTemplate | undefined> => {
    let response: Record<string, BuildingTypeTemplate> = {};
    let library: BuildingTypeTemplate | undefined = undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parseNumbers = (name: any) => {
      if (!isNaN(name) && name.charAt(0) !== '0') {
        name = name % 1 === 0 ? parseInt(name, 10) : parseFloat(name);
      }
      return name;
    };

    response = (await xml2js.parseStringPromise(xml, {
      explicitArray: false,
      ignoreAttrs: true,
      emptyTag: undefined,
      valueProcessors: [parseNumbers, xml2js.processors.parseBooleans],
    })) as Record<string, BuildingTypeTemplate>;

    if (response.BuildingTypeTemplate) {
      library = response.BuildingTypeTemplate;
    }

    return library;
  };

  const importLibraryFromFile = async (
    uploadedFile: File
  ): Promise<BuildingTypeTemplate | undefined> => {
    let library: BuildingTypeTemplate | undefined = undefined;
    try {
      const data = await new Response(uploadedFile).text();
      if (uploadedFile.type.includes('xml')) {
        library = await importLibrary(data);
      } else {
        const deserialized = JSON.parse(data) as {
          BuildingTypeTemplate: BuildingTypeTemplate;
        };
        library = deserialized.BuildingTypeTemplate;
      }
      if (library) {
        if (!library.GlobalKey || !library.ProfessionCode) {
          snack('snack.file-read-error', false);
        }
      } else {
        snack('snack.file-read-error', false);
      }
    } catch (e) {
      console.log(e);
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    return library;
  };

  const validatePriceListFile = async (
    uploadedFile: File
  ): Promise<boolean> => {
    let valid = false;

    if (
      uploadedFile.name.toLowerCase().includes('.csv') ||
      uploadedFile.name.toLowerCase().includes('.xlsx') ||
      uploadedFile.name.toLowerCase().includes('.txt')
    ) {
      valid = true;
    }
    return valid;
  };

  const createTotals = async (
    impProj: ImportedProject
  ): Promise<ProjectTotals> => {
    const { buildProjectCalculation, recalculateTotals } = useCalculation();

    let totals: ProjectTotals = {
      AmountControl: 0,
      Hours: 0,
      MaterialsSubContractors: 0,
      MaterialsUE: 0,
      Profit: 0,
      SelfCost: 0,
      SelfCostMaterialsSubContractors: 0,
      Total: 0,
    };

    if (impProj.BuildingElements && impProj.BuildingElements.length === 0) {
      return totals;
    }

    const token: ProjectConcurrencyToken = {
      Token: uuidv4(),
    };

    const calculation: Calculation = {
      BuildingElements: impProj.BuildingElements,
      Factors: impProj.Factors,
      AmountControl: null,
      Id: impProj.Project.Id ?? '',
      ConcurrencyToken: token,
      CostSoFar: null,
      Hours: null,
      IsLocked: false,
      MaterialsSubContractors: null,
      MaterialsUE: null,
      Name: impProj.Project.Name ?? '',
      Profession: impProj.Project.Profession,
      Profit: null,
      SelfCost: null,
      SelfCostMaterialsSubContractors: null,
      Total: null,
    };

    const projCalc = await buildProjectCalculation(calculation);

    if (projCalc != null) {
      totals = recalculateTotals(projCalc);
    }

    return totals;
  };

  const removeAmounts = (importProj: ImportedProject) => {
    importProj.BuildingElements.forEach(ele => {
      ele.Amount = null;

      ele.AdditionItems.forEach(add => {
        add.Amount = 0;
      });

      ele.Items.forEach(item => {
        item.Amount = null;
      });
    });
  };

  const createImportProject = async (
    nsProject: NstNS3459,
    parent?: ProjectOverview,
    projectName?: string,
    includeAmount?: boolean
  ): Promise<ImportedProject | undefined> => {
    state.loading = true;

    if (!nsProject.prosjekterField) return undefined;
    const nstMain = nsProject.prosjekterField.prosjektNSField[0];
    const profession = await getProfessionFromNstProsjektNS(nstMain);
    const { getNewProjectNumber } = useProject();

    if (!profession) {
      errorModal(undefined, {
        message: `${translate('errors.message-no-prof')}`,
        title: `${translate('errors.title-no-prof')}`,
      });
      return;
    }

    const mainProject: Project = {
      Id: uuidv4(),
      Profession: profession,
      ParentId: parent && parent.Id,
    };

    const payload: ImportedProject = {
      Project: mainProject,
      BuildingElements: [],
      Children: [],
      Factors: [],
      ProjectContacts: [],
      Profession: profession,
      ProjectTotals: {},
    };

    mainProject.Name = projectName ?? nstMain.navnField;
    mainProject.Description = nstMain.beskrivelseField;

    const { buildProjectFromNST } = useProjectBuilder();

    const buildSuccess = await buildProjectFromNST(payload, nstMain);

    if (buildSuccess && parent) {
      const calculationNumber = await getNewProjectNumber(parent.Id);

      payload.BuildingElements = payload.BuildingElements.sort((a, b) =>
        (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
          ? 1
          : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
          ? -1
          : 0
      );

      payload.BuildingElements.forEach((ele, index) => {
        ele.SortOrder = index;
      });

      payload.BuildingElements.forEach(element => {
        element.Items = element.Items.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        element.Items.forEach((item, index) => {
          item.SortOrder = index;
        });

        element.AdditionItems = element.AdditionItems.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        element.AdditionItems.forEach((addition, index) => {
          addition.SortOrder = index;
        });
      });

      if (includeAmount != null && includeAmount === false) {
        removeAmounts(payload);
      }

      payload.ProjectTotals = await createTotals(payload);

      payload.Project.ProjectNumber = calculationNumber;
      return payload;
    } else if (buildSuccess) {
      nsProject.prosjekterField.prosjektNSField.shift();

      for (const calc of nsProject.prosjekterField.prosjektNSField) {
        let calcProfession = await getProfessionFromNstProsjektNS(calc);

        if (!calcProfession) calcProfession = profession;

        const newCalculation: Project = {
          Name: calc.navnField,
          Id: uuidv4(),
          Profession: calcProfession,
          Description: calc.beskrivelseField,
          ParentId: mainProject.Id,
          StreetNumber: mainProject.StreetNumber,
          UsageNumber: mainProject.UsageNumber,
          SectionNumber: mainProject.SectionNumber,
          LocationNumber: mainProject.LocationNumber,
          HousingNumber: mainProject.HousingNumber,
          AddressLine1: mainProject.AddressLine1,
          AddressLine2: mainProject.AddressLine2,
          PostNumber: mainProject.PostNumber,
          PostOffice: mainProject.PostOffice,
        };

        const calculationImport: ImportedProject = {
          Project: newCalculation,
          BuildingElements: [],
          Children: [],
          Factors: [],
          ProjectContacts: [],
          Profession: calcProfession,
          ProjectTotals: {},
        };

        const { buildProjectFromNST } = useProjectBuilder();
        const calcBuildSuccess = await buildProjectFromNST(
          calculationImport,
          calc
        );

        if (calcBuildSuccess) {
          calculationImport.ProjectTotals = await createTotals(
            calculationImport
          );
          mainProject.Children?.push(newCalculation);
          payload.Children.push(calculationImport);
        }
      }
      payload.ProjectTotals = await createTotals(payload);

      payload.Project.ProjectNumber = await getNewProjectNumber();

      payload.BuildingElements = payload.BuildingElements.sort((a, b) =>
        (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
          ? 1
          : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
          ? -1
          : 0
      );

      payload.BuildingElements.forEach((ele, index) => {
        ele.SortOrder = index;
      });

      payload.BuildingElements.forEach(element => {
        element.Items = element.Items.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        element.Items.forEach((item, index) => {
          item.SortOrder = index;
        });

        element.AdditionItems = element.AdditionItems.sort((a, b) =>
          (a.SortOrder ?? 0) > (b.SortOrder ?? 0)
            ? 1
            : (b.SortOrder ?? 0) > (a.SortOrder ?? 0)
            ? -1
            : 0
        );

        element.AdditionItems.forEach((addition, index) => {
          addition.SortOrder = index;
        });
      });

      if (includeAmount != null && includeAmount === false) {
        removeAmounts(payload);
      }

      return payload;
    }
  };

  const importProject = async (project: ImportedProject): Promise<boolean> => {
    const { post } = useApi('/importproject');
    let success = false;
    state.loading = true;

    try {
      const response = await post(project);
      if (isSuccess(response.status)) {
        success = true;
        snack('snack.import-success', true);
      }
    } catch (e) {
      console.log(e);
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }
    state.loading = false;
    return success;
  };

  return {
    importProject,
    createImportProject,
    parseProjectFile,
    validatePriceListFile,
    importLibraryFromFile,
    parsePriceListCSV,
    parsePriceListExcel,
    ...toRefs(state),
  };
};
