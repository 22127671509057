import { BLOB_PRICELIST_NAME } from '@/constants/common';
import {
  blobToString,
  createCategoryFileName,
  getCategoryIdFromBlobUri,
  getCategoryNameFromUri,
  getPriceListBlobNamePrefix,
} from '@/helpers/azure';
import { CustomError } from '@/models/common/interfaces';
import {
  BlobPriceList,
  BlobPriceListCategoryOverview,
  CategoryChanged,
  CategoryDeleted,
  Facet,
  PriceList,
  PriceListCreated,
  PriceListDeleted,
  PriceListFilterResult,
  PriceListItem,
  PriceListItemDeleted,
  PriceListSearchResult,
  PriceListTreeNode,
  PriceListVM,
  SearchablePriceListItem,
  SearchFilter,
  VareKategori,
} from '@/models/pricebook/interfaces';
import {
  BlobItem,
  BlockBlobClient,
  ContainerClient,
  ContainerListBlobFlatSegmentResponse,
  ContainerListBlobsOptions,
} from '@azure/storage-blob';
import { reactive, toRefs } from '@vue/composition-api';
import { useAzure } from './azure';
import * as pako from 'pako';
import { translate } from '@/localization';
import { useSnackbar } from './snackbar';
import { isSuccess, unitDescriptionConverter } from '@/helpers/common';
import { useApi } from './api';
import { useErrorModal } from './error';
import { v4 as uuidv4 } from 'uuid';

interface State {
  error?: CustomError;
  activePriceListItems?: Array<PriceListItem>;
  pricelists: Array<PriceListTreeNode> | undefined;
  priceListTitle?: string;
  activePricelist?: PriceListTreeNode | undefined;
  activePricelistCategory?: BlobPriceListCategoryOverview | undefined;
  companyPricelistCategories: Array<BlobPriceListCategoryOverview> | undefined;
  companyBlobPriceLists: Array<BlobPriceList> | undefined;
  sharedBlobPriceLists: Array<BlobPriceList> | undefined;
  loading: boolean;
  uploadingPriceList: boolean;
  loadingPricelists: boolean;
  statusText: string;
  priceListName: string;
}

const state = reactive<State>({
  error: undefined,
  activePriceListItems: undefined,
  priceListTitle: undefined,
  activePricelist: undefined,
  activePricelistCategory: undefined,
  loading: false,
  companyBlobPriceLists: undefined,
  sharedBlobPriceLists: undefined,
  pricelists: undefined,
  uploadingPriceList: false,
  loadingPricelists: false,
  statusText: '',
  priceListName: '',
  companyPricelistCategories: undefined,
});

export const usePriceBook = () => {
  const { snack } = useSnackbar();
  const { errorModal } = useErrorModal();

  const setActivePricelist = (list: PriceListTreeNode) => {
    state.activePricelist = list;
    state.priceListTitle = `${list.Name ?? '?'}`;
  };

  const destructPriceLists = () => {
    state.error = undefined;
    state.activePriceListItems = undefined;
    state.priceListTitle = undefined;
    state.activePricelist = undefined;
    state.activePricelistCategory = undefined;
    state.loading = false;
    state.companyBlobPriceLists = undefined;
    state.sharedBlobPriceLists = undefined;
    state.pricelists = undefined;
    state.uploadingPriceList = false;
    state.loadingPricelists = false;
    state.statusText = '';
    state.priceListName = '';
    state.companyPricelistCategories = undefined;
  };

  const setActivePricelistCategory = (
    category: BlobPriceListCategoryOverview
  ) => {
    state.activePricelistCategory = category;
    state.priceListTitle = `${state.activePricelist?.Name ??
      '?'} - ${category.Name ?? '?'} (${state.activePriceListItems?.length ??
      ''})`;
  };

  const updatePricesFromNOBBNumbers = async (
    priceListId: string,
    nobbNumbersInCalculation: Array<number>
  ): Promise<Array<SearchablePriceListItem>> => {
    let spli: Array<SearchablePriceListItem> = [];

    interface SearchPayload {
      PriceListId: string;
      NobbNumbersInCalculation: Array<number>;
    }

    const payload: SearchPayload = {
      NobbNumbersInCalculation: nobbNumbersInCalculation,
      PriceListId: priceListId,
    };

    const { post } = useApi('/azuresearch/');

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        spli = response.data as Array<SearchablePriceListItem>;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return spli;
  };

  const searchProductsInPricelists = async (
    searchFilter: SearchFilter
  ): Promise<PriceListSearchResult> => {
    let result: PriceListSearchResult = {
      CategoryFacets: [],
      FoundPriceLists: [],
      PriceListFacets: [],
      TotalResultCount: 0,
    };

    const { post } = useApi('/azuresearch/search');

    try {
      const response = await post(searchFilter);
      if (isSuccess(response.status)) {
        result = response.data as PriceListSearchResult;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return result;
  };

  const getPriceListFacets = async (
    searchFilter: SearchFilter
  ): Promise<Array<Facet>> => {
    let facets: Array<Facet> = [];

    const { post } = useApi('/azuresearch/pricelistfacets');

    try {
      const response = await post(searchFilter);
      if (isSuccess(response.status)) {
        facets = response.data as Array<Facet>;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return facets;
  };

  const getCategoryFacets = async (
    searchFilter: SearchFilter
  ): Promise<Array<Facet>> => {
    let facets: Array<Facet> = [];

    const { post } = useApi('/azuresearch/categoryfacets');

    try {
      const response = await post(searchFilter);
      if (isSuccess(response.status)) {
        facets = response.data as Array<Facet>;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return facets;
  };

  const searchProductsInPricelistsWithFilter = async (
    searchFilter: SearchFilter
  ): Promise<PriceListFilterResult> => {
    let result: PriceListFilterResult = {
      FoundPriceLists: [],
      TotalResultCount: 0,
    };

    const { post } = useApi('/azuresearch/filters');

    try {
      const response = await post(searchFilter);
      if (isSuccess(response.status)) {
        result = response.data as PriceListFilterResult;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
      } else {
        snack('snack.sorry', false);
      }
    }

    return result;
  };

  const destroyDownloaded = (full?: boolean) => {
    if (full) {
      state.activePricelist = undefined;
    }
    state.priceListTitle = undefined;
    state.activePriceListItems = undefined;
    state.activePricelistCategory = undefined;
  };

  const notifyPriceListDeleted = async (
    priceListId: string
  ): Promise<boolean> => {
    const { post } = useApi('/blobpricelists/events/pricelistdeleted');

    let success = false;

    const payload: PriceListDeleted = {
      PriceListId: priceListId,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
        success = false;
      } else {
        snack('snack.sorry', false);
        success = false;
      }
    }

    return success;
  };

  const notifyCategoryDeleted = async (
    priceListId: string,
    categoryBlobName: string,
    categoryId: string,
    categoryName: string
  ): Promise<boolean> => {
    const { post } = useApi('/blobpricelists/events/categorydeleted');

    let success = false;

    const payload: CategoryDeleted = {
      PriceListId: priceListId,
      CategoryBlobName: categoryBlobName,
      CategoryId: categoryId,
      CategoryName: categoryName,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
        success = false;
      } else {
        snack('snack.sorry', false);
        success = false;
      }
    }

    return success;
  };

  const notifyPriceListItemDeleted = async (
    priceListItemId: string
  ): Promise<boolean> => {
    const { post } = useApi('/blobpricelists/events/pricelistitemdeleted');

    let success = false;

    const payload: PriceListItemDeleted = {
      PriceListItemId: priceListItemId,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
        success = false;
      } else {
        snack('snack.sorry', false);
        success = false;
      }
    }

    return success;
  };

  const notifyCategoryChanged = async (
    priceListId: string,
    categoryBlobName: string,
    categoryId: string,
    categoryName: string
  ): Promise<boolean> => {
    const { post } = useApi('/blobpricelists/events/categorychanged');

    let success = false;

    const payload: CategoryChanged = {
      PriceListId: priceListId,
      CategoryBlobName: categoryBlobName,
      CategoryId: categoryId,
      CategoryName: categoryName,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
        success = false;
      } else {
        snack('snack.sorry', false);
        success = false;
      }
    }

    return success;
  };

  const notifyPriceListCreated = async (
    priceListId: string
  ): Promise<boolean> => {
    const { post } = useApi('/blobpricelists/events/pricelistcreated');

    let success = false;

    const payload: PriceListCreated = {
      PriceListId: priceListId,
    };

    try {
      const response = await post(payload);
      if (isSuccess(response.status)) {
        success = true;
      }
    } catch (e) {
      state.error = e.response;
      if (state.error) {
        errorModal(state.error);
        success = false;
      } else {
        snack('snack.sorry', false);
        success = false;
      }
    }

    return success;
  };

  const getSharedPricelists = async (): Promise<Array<PriceListTreeNode>> => {
    const { getSharedContainer } = useAzure();
    let blobPriceLists: Array<BlobPriceList> = [];
    const priceLists: Array<PriceListTreeNode> = [];

    const container = await getSharedContainer();

    if (!container) return priceLists;

    try {
      const blobClient = container.getBlobClient(BLOB_PRICELIST_NAME);

      const downloadBlockBlobResponse = await blobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const downloaded = await blobToString(blobBody);
        blobPriceLists = JSON.parse(downloaded as string);
      }

      blobPriceLists.forEach(bpl => {
        const pricelist: PriceListTreeNode = {
          CompanyPriceList: bpl.CompanyPriceList,
          Id: bpl.Id,
          IsSubscription: bpl.IsSubscription,
          Name: bpl.Name,
          PriceFileId: bpl.PriceFileId,
          categories: [],
        };

        priceLists.push(pricelist);
      });
    } catch (e) {
      console.log(e);
    }

    return priceLists;
  };

  const getCompanyPricelists = async (): Promise<Array<PriceListTreeNode>> => {
    const { getCompanyContainer } = useAzure();
    let blobPriceLists: Array<BlobPriceList> = [];
    const priceLists: Array<PriceListTreeNode> = [];

    const container = await getCompanyContainer();

    if (!container) return priceLists;

    try {
      const blobClient = container.getBlobClient(BLOB_PRICELIST_NAME);

      const downloadBlockBlobResponse = await blobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const downloaded = await blobToString(blobBody);
        blobPriceLists = JSON.parse(downloaded as string);
      }

      blobPriceLists.forEach(bpl => {
        const pricelist: PriceListTreeNode = {
          CompanyPriceList: bpl.CompanyPriceList,
          Id: bpl.Id,
          IsSubscription: bpl.IsSubscription,
          Name: bpl.Name,
          PriceFileId: bpl.PriceFileId,
          categories: [],
        };

        priceLists.push(pricelist);
      });
    } catch (e) {
      console.log(e);
      snack('snack.sorry', false);
    }

    return priceLists;
  };

  const getSubscribedPricelists = async (): Promise<Array<
    PriceListTreeNode
  >> => {
    const allPriceLists = await getCompanyPricelists();

    const subscribedLists = allPriceLists.filter(pl => pl.IsSubscription);

    return subscribedLists;
  };

  const getPriceLists = async (
    force?: boolean
  ): Promise<Array<PriceListTreeNode>> => {
    if (state.pricelists && !force) {
      return state.pricelists;
    }

    state.loadingPricelists = true;
    state.pricelists = [];

    const companyLists = await getCompanyPricelists();
    const sharedLists = await getSharedPricelists();

    companyLists.forEach(cl => {
      if (cl.IsSubscription) {
        const found = sharedLists.find(spl => spl.Id === cl.Id);
        if (!found) {
          cl.Name += ` (${translate('info.pricelist-unavailable')})`;
        }
      }
    });

    state.pricelists = companyLists;
    state.loadingPricelists = false;

    return state.pricelists;
  };

  const getPriceListCategoriesOverview = async (
    priceList: PriceListTreeNode
  ): Promise<Array<BlobPriceListCategoryOverview>> => {
    const { getCompanyContainer, getSharedContainer } = useAzure();
    const categoryOverviews: Array<BlobPriceListCategoryOverview> = [];
    const blobs: Array<BlobItem> = [];
    let marker: string | undefined = undefined;
    let priceListBlobNamePrefix = '';
    let container: ContainerClient | undefined = undefined;

    if (priceList.IsSubscription) {
      container = await getSharedContainer();
    } else {
      container = await getCompanyContainer();
    }

    if (!container || !priceList.Id) return categoryOverviews;

    priceListBlobNamePrefix = getPriceListBlobNamePrefix(priceList.Id);
    // Get the category prefix based on the ID of the pricelist selected
    const options: ContainerListBlobsOptions = {
      prefix: priceListBlobNamePrefix,
    };

    do {
      const iter: AsyncIterableIterator<ContainerListBlobFlatSegmentResponse> = await container
        .listBlobsFlat(options)
        .byPage({ continuationToken: marker });

      const resp = (await iter.next()).value;

      for (const blob of resp.segment.blobItems) {
        blobs.push(blob);
      }

      marker = resp.continuationToken;
    } while (marker);

    blobs.forEach(blob => {
      const id = getCategoryIdFromBlobUri(blob.name);
      const name = getCategoryNameFromUri(blob.name);

      if (priceList.Id) {
        const priceListCategory: BlobPriceListCategoryOverview = {
          BlobName: blob.name,
          Id: id,
          Name: name,
          ListBlobItem: blob,
          PriceListId: priceList.Id,
          SizeInBytes: blob.properties.contentLength,
        };

        categoryOverviews.push(priceListCategory);
      }
    });

    return categoryOverviews.sort((a, b) => {
      if (a.Name && b.Name && a.Name < b.Name) {
        return -1;
      }
      if (a.Name && b.Name && a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
  };

  const downloadCategory = async (category: BlobPriceListCategoryOverview) => {
    const { getCompanyContainer, getSharedContainer } = useAzure();
    const { activePricelist } = usePriceBook();

    let blockBlobClient: BlockBlobClient | undefined = undefined;
    let container: ContainerClient | undefined = undefined;

    if (
      !activePricelist?.value ||
      category.PriceListId !== activePricelist.value.Id
    )
      return;

    if (activePricelist.value.IsSubscription) {
      container = await getSharedContainer();
    } else {
      container = await getCompanyContainer();
    }

    if (!container) return;

    blockBlobClient = container.getBlockBlobClient(category.BlobName);

    if (!blockBlobClient) return;

    state.loading = true;

    const downloadBlockBlobResponse = await blockBlobClient.download();
    const blobBody = await downloadBlockBlobResponse.blobBody;

    if (blobBody) {
      const orig = pako.ungzip(new Uint8Array(await blobBody.arrayBuffer()), {
        to: 'string',
      });
      state.activePriceListItems = JSON.parse(orig);
    }

    state.loading = false;
  };

  const savePriceListItemsToCategoryBlockBlob = async (del: boolean) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();

    if (
      !state.activePricelistCategory ||
      !container ||
      !state.activePriceListItems
    )
      return;

    const blobBlockClient = container.getBlockBlobClient(
      state.activePricelistCategory.BlobName
    );

    const stringifiedItemList = JSON.stringify(state.activePriceListItems);
    const newOrig = pako.gzip(stringifiedItemList);

    try {
      const uploadBlobResponse = await blobBlockClient.upload(
        newOrig,
        newOrig.length
      );

      if (uploadBlobResponse) {
        del
          ? snack('snack.pricelist-item-deleted', true)
          : snack('snack.pricelist-item-updated', true);
      }
    } catch (err) {
      console.log(err);
      snack('snack.sorry', false);
    }
  };

  const deletePriceListItemInternal = (priceListItemId: string) => {
    if (state.activePriceListItems && state.activePriceListItems.length > 0) {
      const index = state.activePriceListItems.findIndex(
        i => i.Id === priceListItemId
      );
      if (index >= 0) {
        state.activePriceListItems.splice(index, 1);
      }
    }
  };

  const insertPriceListItem = async (
    category: BlobPriceListCategoryOverview,
    item: PriceListItem
  ) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();

    if (!container) return;

    try {
      const blobBlockClient = container.getBlockBlobClient(category.BlobName);
      const downloadBlockBlobResponse = await blobBlockClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const orig = pako.ungzip(new Uint8Array(await blobBody.arrayBuffer()), {
          to: 'string',
        });
        const items: Array<PriceListItem> = JSON.parse(orig);
        items.push(item);
        const stringifiedItemList = JSON.stringify(items);
        const newOrig = pako.gzip(stringifiedItemList);

        const uploadBlobResponse = await blobBlockClient.upload(
          newOrig,
          newOrig.length
        );

        if (uploadBlobResponse && category.Name) {
          await notifyCategoryChanged(
            category.PriceListId,
            category.BlobName,
            category.Id,
            category.Name
          );
        }

        if (
          state.activePricelistCategory?.BlobName &&
          state.activePriceListItems &&
          state.activePricelistCategory.BlobName === category.BlobName &&
          state.activePricelist?.Id === category.PriceListId
        ) {
          state.activePriceListItems.push(item);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updatePriceListItem = async (priceListItem: PriceListItem) => {
    if (
      !state.activePricelistCategory?.Name ||
      !state.activePricelist?.Id ||
      !state.activePriceListItems
    )
      return;

    deletePriceListItemInternal(priceListItem.Id);
    state.activePriceListItems.push(priceListItem);
    await savePriceListItemsToCategoryBlockBlob(false);

    await notifyCategoryChanged(
      state.activePricelist.Id,
      state.activePricelistCategory.BlobName,
      state.activePricelistCategory.Id,
      state.activePricelistCategory.Name
    );
  };

  const deletePriceListItem = async (priceListItem: PriceListItem) => {
    if (
      !state.activePricelistCategory?.Name ||
      !state.activePricelist?.Id ||
      !state.activePriceListItems
    )
      return;

    deletePriceListItemInternal(priceListItem.Id);
    await savePriceListItemsToCategoryBlockBlob(true);

    await notifyCategoryChanged(
      state.activePricelist.Id,
      state.activePricelistCategory.BlobName,
      state.activePricelistCategory.Id,
      state.activePricelistCategory.Name
    );
  };

  const deletePricelist = async (
    pricelistId: string,
    isSubscription: boolean
  ) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();
    let blobPriceLists: Array<BlobPriceList> = [];

    if (!container || !pricelistId) return;

    try {
      const blockBlobClient = container.getBlockBlobClient(BLOB_PRICELIST_NAME);

      const downloadBlockBlobResponse = await blockBlobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const downloaded = await blobToString(blobBody);
        blobPriceLists = JSON.parse(downloaded as string);

        if (blobPriceLists) {
          blobPriceLists = blobPriceLists.filter(
            blob => blob.Id !== pricelistId
          );
        }

        const payload = JSON.stringify(blobPriceLists);

        const uploadBlobResponse = await blockBlobClient.upload(
          payload,
          payload.length,
          {}
        );

        const notified = !isSubscription
          ? await notifyPriceListDeleted(pricelistId)
          : true;

        if (notified && uploadBlobResponse) {
          snack('snack.pricelist-deleted', true);
          await getPriceLists(true);
        }
      }
    } catch (e) {
      console.log(e);
      snack('snack.sorry', false);
    }
  };

  const deleteCategoryFromPriceList = async (
    category: BlobPriceListCategoryOverview
  ) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();

    if (!container || !category.BlobName) return;
    const categoryBlobBlockClient = container.getBlockBlobClient(
      category.BlobName
    );

    try {
      const trashcanCategoryBlobBlockClient = container.getBlockBlobClient(
        `trashcan-${category.BlobName}`
      );

      const downloadBlockBlobResponse = await categoryBlobBlockClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      //COPY-BLOB

      if (blobBody) {
        const orig = pako.ungzip(new Uint8Array(await blobBody.arrayBuffer()), {
          to: 'string',
        });
        const newOrig = pako.gzip(orig);
        const uploadBlobResponse = await trashcanCategoryBlobBlockClient.upload(
          newOrig,
          newOrig.length
        );

        if (uploadBlobResponse) {
          const deleteResponse = await categoryBlobBlockClient.delete();
          if (deleteResponse && category.Name) {
            await notifyCategoryDeleted(
              category.PriceListId,
              trashcanCategoryBlobBlockClient.name,
              category.Id,
              category.Name
            );
            snack('snack.category-deleted', true);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCompanyPricelistCategories = async (
    force?: boolean
  ): Promise<void> => {
    if (state.companyPricelistCategories && !force) return;

    const companyPriceLists = await getCompanyPricelists();
    const companyList = companyPriceLists.find(
      cl => cl.CompanyPriceList === true
    );
    if (companyList) {
      state.companyPricelistCategories = await getPriceListCategoriesOverview(
        companyList
      );
    }
  };

  const addNewCategoryToPriceList = async (
    priceListId: string,
    category: VareKategori,
    notify: boolean
  ) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();

    if (!container || !category.Text) return;

    const categoryId = uuidv4();
    const categoryFileName = createCategoryFileName(categoryId, category.Text);

    const priceListPrefix = getPriceListBlobNamePrefix(priceListId);

    const newCategoryBlobName = `${priceListPrefix}${categoryFileName}`;

    const categoryBlobBlockClient = container.getBlockBlobClient(
      newCategoryBlobName
    );

    const items: Array<PriceListItem> = [];
    category.Varer.forEach(v => {
      const priceListItem: PriceListItem = {
        Id: uuidv4(),
        Name: v.ItemText,
        ItemNumber: v.ItemNumber,
        NOBBNumber: v.ItemNumberNOBB,
        DiscountPercentage: v.Discount,
        BasePrice: v.BasePrice,
        Description: v.ItemText2,
        Unit: unitDescriptionConverter(v.UnitDesc ?? ''),
        PackageUnitDesc: unitDescriptionConverter(v.PackageUnitDesc ?? ''),
        ItemsInPackage: v.ItemsInPackage,
        Price: null,
      };

      items.push(priceListItem);
    });

    const stringifiedItemList = JSON.stringify(items);
    const newOrig = pako.gzip(stringifiedItemList);

    const uploadBlobResponse = await categoryBlobBlockClient.upload(
      newOrig,
      newOrig.length
    );

    if (uploadBlobResponse && notify) {
      snack('snack.category-created', true);
      if (state.companyPricelistCategories) {
        await getCompanyPricelistCategories(true);
      }
    }
  };

  const canUploadList = (lists: Array<BlobPriceList>): boolean => {
    let yesNo = true;
    if (lists) {
      const notSubscribed = lists.filter(pl => !pl.IsSubscription);
      if (notSubscribed && notSubscribed.length >= 5) {
        yesNo = false;
        snack('snack.too-many-lists', false);
        state.uploadingPriceList = false;
        state.statusText = '';
        state.priceListName = '';
      }
    }
    return yesNo;
  };

  const uploadPriceList = async (priceList: PriceListVM) => {
    const { getCompanyContainer } = useAzure();
    const container = await getCompanyContainer();

    let blobPriceLists: Array<BlobPriceList> = [];

    if (!container) return;

    state.uploadingPriceList = true;
    state.priceListName = priceList.Name;
    const priceListId = uuidv4();

    try {
      const blockBlobClient = container.getBlockBlobClient(BLOB_PRICELIST_NAME);
      state.statusText = `${translate('loader.validating')}`;
      const downloadBlockBlobResponse = await blockBlobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const downloaded = await blobToString(blobBody);
        blobPriceLists = JSON.parse(downloaded as string);

        if (blobPriceLists && canUploadList(blobPriceLists)) {
          state.statusText = `${translate('loader.starting-upload')}`;
          const newPriceList: BlobPriceList = {
            CompanyPriceList: false,
            Id: priceListId,
            IsSubscription: false,
            Name: priceList.Name,
            PriceFileId: null,
          };

          blobPriceLists.push(newPriceList);

          const payload = JSON.stringify(blobPriceLists);

          const uploadBlobResponse = await blockBlobClient.upload(
            payload,
            payload.length,
            {}
          );

          if (uploadBlobResponse) {
            for (const category of priceList.Categories) {
              state.statusText = `${translate('loader.creating-category', [
                category.Text,
              ])}`;

              if (!category.Text) continue;
              await addNewCategoryToPriceList(priceListId, category, false);
            }
          }

          await notifyPriceListCreated(priceListId);
          state.uploadingPriceList = false;
          state.statusText = '';
          state.priceListName = '';
          state.activePricelistCategory = undefined;
          state.activePricelist = undefined;
          state.activePriceListItems = undefined;
          await getPriceLists(true);
        }
      }
    } catch (e) {
      console.log(e);
      state.statusText = `${translate('loader.undo')}`;
      await deletePricelist(priceListId, false);
      snack('snack.sorry', false);
    }
  };

  const convertPriceListNodeTreeToPriceListArray = (
    plNodes: Array<PriceListTreeNode>
  ): Array<PriceList> => {
    const pricelists: Array<PriceList> = [];
    plNodes.forEach(pln => {
      const pl: PriceList = {
        CompanyPriceList: pln.CompanyPriceList,
        Id: pln.Id,
        IsSubscription: pln.IsSubscription,
        Name: pln.Name,
        PriceFileId: pln.PriceFileId,
      };

      pricelists.push(pl);
    });
    return pricelists;
  };

  const getDownloadableFiles = async (): Promise<Array<PriceList>> => {
    const downloadableFiles: Array<PriceList> = [];

    const sharedFiles = await getSharedPricelists();
    const companyFiles = await getCompanyPricelists();

    if (!sharedFiles || !companyFiles) return downloadableFiles;

    const allFiles = convertPriceListNodeTreeToPriceListArray(sharedFiles);
    const subscribedPriceLists = convertPriceListNodeTreeToPriceListArray(
      companyFiles
    );

    allFiles.forEach(file => {
      const found = subscribedPriceLists.find(
        subscribedFile => subscribedFile.Id === file.Id
      );
      if (!found) {
        downloadableFiles.push(file);
      }
    });

    return downloadableFiles;
  };

  const subscribeToPriceList = async (
    priceList: PriceList
  ): Promise<boolean> => {
    const { getCompanyContainer } = useAzure();
    const companyContainer = await getCompanyContainer();
    let blobPriceLists: Array<BlobPriceList> = [];

    let success = false;

    if (!companyContainer || !priceList.Id) return success;

    try {
      const blockBlobClient = companyContainer.getBlockBlobClient(
        BLOB_PRICELIST_NAME
      );

      const downloadBlockBlobResponse = await blockBlobClient.download();
      const blobBody = await downloadBlockBlobResponse.blobBody;

      if (blobBody) {
        const downloaded = await blobToString(blobBody);
        blobPriceLists = JSON.parse(downloaded as string);

        if (blobPriceLists) {
          const newSubscriptionPriceList: PriceList = {
            CompanyPriceList: priceList.CompanyPriceList,
            Id: priceList.Id,
            IsSubscription: true,
            Name: priceList.Name,
            PriceFileId: priceList.PriceFileId,
          };

          blobPriceLists.push(newSubscriptionPriceList);
        }

        const payload = JSON.stringify(blobPriceLists);

        const uploadBlobResponse = await blockBlobClient.upload(
          payload,
          payload.length,
          {}
        );

        if (uploadBlobResponse) {
          snack('snack.pricelist-added', true);
          await getPriceLists(true);
          success = true;
        }
      }
    } catch (e) {
      console.log(e);
      snack('snack.sorry', false);
      success = false;
    }
    return success;
  };

  return {
    getPriceLists,
    getCompanyPricelists,
    getSharedPricelists,
    getPriceListCategoriesOverview,
    getSubscribedPricelists,
    downloadCategory,
    destroyDownloaded,
    setActivePricelist,
    setActivePricelistCategory,
    deletePricelist,
    deleteCategoryFromPriceList,
    deletePriceListItem,
    insertPriceListItem,
    notifyPriceListDeleted,
    notifyCategoryDeleted,
    notifyPriceListItemDeleted,
    notifyCategoryChanged,
    notifyPriceListCreated,
    updatePriceListItem,
    uploadPriceList,
    addNewCategoryToPriceList,
    getDownloadableFiles,
    subscribeToPriceList,
    getCompanyPricelistCategories,
    updatePricesFromNOBBNumbers,
    searchProductsInPricelists,
    searchProductsInPricelistsWithFilter,
    getCategoryFacets,
    getPriceListFacets,
    destructPriceLists,
    ...toRefs(state),
  };
};
