import {
  createReportName,
  downloadExcel,
  getMileStoneData,
} from '@/helpers/reports';
import { defaultLocaleString, translate } from '@/localization';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  NO_MILESTONE,
  ReportFileType,
  ReportType,
} from '@/models/report//enums';
import { createHeader, createHeaderXlsx } from '@/models/report/report-header';
import {
  createProjectInfo,
  createProjectInfoXlsx,
} from '@/models/report/report-project-info';
import { useCalculation } from '@/modules/calculation';
import { ReportOptions } from '@/models/report/interfaces';
import { utils, write } from 'xlsx';
import {
  getNameByProfessionCode,
  numberToAbsStringOrEmpty,
} from '@/helpers/common';
import {
  DEFAULT_TEXT_LARGE,
  DEFAULT_TEXT_MEDIUM,
  DEFAULT_TEXT_SMALL,
  DEFAULT_TEXT_XSMALL,
  LARGE_TEXT_LARGE,
  LARGE_TEXT_MEDIUM,
  LARGE_TEXT_SMALL,
  LARGE_TEXT_XSMALL,
} from '@/constants/styles';
import { useReport } from '@/modules/report';

export const generateProjectMilestoneReportPDF = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();

  if (options.Calculations.length === 0) return;

  const doc = new jsPDF('p', 'mm', [210, 297]);
  const pdfName = createReportName(type, ReportFileType.PDF, true);

  await createHeader(doc, options);
  await createProjectInfo(doc, false, options, true);

  const reportName = `${translate('reports.project-milestone-report')}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const first = (doc as any).autoTable.previous;
  doc.text(reportName, 14, first.finalY + 9);

  let projectSumSelfCost = 0;
  let projectSumhours = 0;

  let calculationCount = 0;
  for (const calc of options.Calculations) {
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc || projectCalc.BuildingElements.length === 0) continue;
        calculationCount++;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const first = (doc as any).autoTable.previous;

        const head = [
          [
            translate('labels.milestone'),
            translate('labels.building-element'),
            translate('labels.building-item'),
            translate('labels.unit'),
            translate('labels.amount'),
            translate('labels.sum-selfcost-mat-sub'),
            translate('labels.hours'),
          ],
        ];

        const calcName = `${projectCalc.Name} - ${getNameByProfessionCode(
          projectCalc.Profession.Code
        )}`;

        autoTable(doc, {
          startY: calculationCount === 1 ? first.finalY + 14 : first.finalY + 6,
          head: [[calcName]],
          theme: 'plain',
          styles: {
            fontSize:
              options.FontSize === 'default'
                ? DEFAULT_TEXT_LARGE
                : LARGE_TEXT_LARGE,
            halign: 'left',
          },
        });

        const milestones = getMileStoneData(projectCalc, false);
        const withoutMilestone = milestones.get(NO_MILESTONE);

        if (milestones) {
          if (withoutMilestone) {
            const mileStonedata: (string | number)[][] = [];

            withoutMilestone.forEach(item => {
              const row = [
                item.MileStone ?? '',
                item.BuildingElement?.Name ?? '',
                item.ItemName ?? '',
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors,
                  2
                ),
                numberToAbsStringOrEmpty(item.SumHours, 2),
              ];

              mileStonedata.push(row);
            });

            projectSumSelfCost += withoutMilestone.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
            }, 0);
            projectSumhours += withoutMilestone.reduce((prev, cur) => {
              return prev + (cur.SumHours ?? 0);
            }, 0);

            const lastRow = [
              '',
              '',
              '',
              '',
              '',
              numberToAbsStringOrEmpty(
                withoutMilestone.reduce((prev, cur) => {
                  return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
                }, 0),
                2
              ),
              numberToAbsStringOrEmpty(
                withoutMilestone.reduce((prev, cur) => {
                  return prev + (cur.SumHours ?? 0);
                }, 0),
                2
              ),
            ];

            mileStonedata.push(lastRow);

            const milestoneHeader = [[translate(`labels.${NO_MILESTONE}`)]];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const second = (doc as any).autoTable.previous;

            autoTable(doc, {
              startY: second.finalY + 2,
              head: milestoneHeader,
              theme: 'plain',
              styles: {
                fontStyle: 'italic',
              },
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const third = (doc as any).autoTable.previous;

            autoTable(doc, {
              startY: third.finalY + 2,
              showHead: 'firstPage',
              head: head,
              body: mileStonedata,
              headStyles: {
                fillColor: '#656d72',
                fontSize:
                  options.FontSize === 'default'
                    ? DEFAULT_TEXT_XSMALL
                    : LARGE_TEXT_XSMALL,
                valign: 'middle',
              },
              styles: {
                fontSize:
                  options.FontSize === 'default'
                    ? DEFAULT_TEXT_XSMALL
                    : LARGE_TEXT_XSMALL,
              },
              didParseCell(data) {
                if (data.row.index === mileStonedata.length - 1) {
                  data.cell.styles.fontStyle = 'bold';
                  data.cell.styles.fillColor = 'white';
                }
              },
              columnStyles: {
                0: {
                  cellWidth: 25,
                  valign: 'middle',
                },
                1: {
                  cellWidth: 'auto',
                  valign: 'middle',
                },
                2: {
                  cellWidth: 'auto',
                  valign: 'middle',
                },
                3: {
                  cellWidth:
                    options.FontSize === 'default'
                      ? DEFAULT_TEXT_LARGE
                      : LARGE_TEXT_LARGE,
                  valign: 'middle',
                  halign: 'center',
                },
                4: {
                  cellWidth:
                    options.FontSize === 'default'
                      ? DEFAULT_TEXT_LARGE
                      : LARGE_TEXT_LARGE,
                  valign: 'middle',
                  halign: 'center',
                },
                5: {
                  cellWidth: 25,
                  valign: 'middle',
                  halign: 'right',
                },
                6: {
                  cellWidth: 15,
                  valign: 'middle',
                  halign: 'right',
                },
              },
            });

            milestones.delete(NO_MILESTONE);
          }

          milestones.forEach((value, key) => {
            const mileStonedata: (string | number)[][] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const first = (doc as any).autoTable.previous;

            value.forEach(item => {
              const row = [
                item.MileStone ?? '',
                item.BuildingElement?.Name ?? '',
                item.ItemName ?? '',
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors,
                  2
                ),
                numberToAbsStringOrEmpty(item.SumHours, 2),
              ];

              mileStonedata.push(row);
            });

            projectSumSelfCost += value.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
            }, 0);
            projectSumhours += value.reduce((prev, cur) => {
              return prev + (cur.SumHours ?? 0);
            }, 0);

            const lastRow = [
              key,
              '',
              '',
              '',
              '',
              numberToAbsStringOrEmpty(
                value.reduce((prev, cur) => {
                  return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
                }, 0),
                2
              ),
              numberToAbsStringOrEmpty(
                value.reduce((prev, cur) => {
                  return prev + (cur.SumHours ?? 0);
                }, 0),
                2
              ),
            ];

            mileStonedata.push(lastRow);

            const milestoneHeader = [
              [`${translate('labels.milestone')} - ${key}`],
            ];

            autoTable(doc, {
              startY: first.finalY + 6,
              head: milestoneHeader,
              theme: 'plain',
              styles: {
                fontStyle: 'italic',
              },
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const second = (doc as any).autoTable.previous;

            autoTable(doc, {
              showHead: 'firstPage',
              startY: second.finalY,
              head: head,
              body: mileStonedata,
              headStyles: {
                fillColor: '#263238',
                fontSize:
                  options.FontSize === 'default'
                    ? DEFAULT_TEXT_XSMALL
                    : LARGE_TEXT_XSMALL,
                valign: 'middle',
              },
              styles: {
                fontSize:
                  options.FontSize === 'default'
                    ? DEFAULT_TEXT_XSMALL
                    : LARGE_TEXT_XSMALL,
              },
              didParseCell(data) {
                if (data.row.index === mileStonedata.length - 1) {
                  data.cell.styles.fontStyle = 'bold';
                  data.cell.styles.fillColor = 'white';
                }
              },
              columnStyles: {
                0: {
                  cellWidth: 25,
                  valign: 'middle',
                },
                1: {
                  cellWidth: 'auto',
                  valign: 'middle',
                },
                2: {
                  cellWidth: 'auto',
                  valign: 'middle',
                },
                3: {
                  cellWidth:
                    options.FontSize === 'default'
                      ? DEFAULT_TEXT_LARGE
                      : LARGE_TEXT_LARGE,
                  valign: 'middle',
                  halign: 'center',
                },
                4: {
                  cellWidth:
                    options.FontSize === 'default'
                      ? DEFAULT_TEXT_LARGE
                      : LARGE_TEXT_LARGE,
                  valign: 'middle',
                  halign: 'center',
                },
                5: {
                  cellWidth: 25,
                  valign: 'middle',
                  halign: 'right',
                },
                6: {
                  cellWidth: 15,
                  valign: 'middle',
                  halign: 'right',
                },
              },
            });
          });
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const last = (doc as any).autoTable.previous;

  const summaryData: (string | number)[][] = [
    [
      `${translate('labels.project-total')}`,
      '',
      '',
      '',
      '',
      numberToAbsStringOrEmpty(projectSumSelfCost, 2),
      numberToAbsStringOrEmpty(projectSumhours, 2),
    ],
  ];

  autoTable(doc, {
    startY: last.finalY + 10,
    body: summaryData,
    theme: 'plain',
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_SMALL : LARGE_TEXT_SMALL,
      fontStyle: 'bold',
    },
    columnStyles: {
      0: {
        cellWidth: 25,
        valign: 'middle',
      },
      1: {
        cellWidth: 'auto',
        valign: 'middle',
      },
      2: {
        cellWidth: 'auto',
        valign: 'middle',
      },
      3: {
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_LARGE
            : LARGE_TEXT_LARGE,
        valign: 'middle',
        halign: 'center',
      },
      4: {
        cellWidth:
          options.FontSize === 'default'
            ? DEFAULT_TEXT_LARGE
            : LARGE_TEXT_LARGE,
        valign: 'middle',
        halign: 'center',
      },
      5: {
        cellWidth: 25,
        valign: 'middle',
        halign: 'right',
      },
      6: {
        cellWidth: 15,
        valign: 'middle',
        halign: 'right',
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pages = (doc.internal as any).getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width; //Optional
  const pageHeight = doc.internal.pageSize.height; //Optional
  doc.setFontSize(
    options.FontSize === 'default' ? DEFAULT_TEXT_MEDIUM : LARGE_TEXT_MEDIUM
  ); //Optional

  for (let j = 1; j < pages + 1; j++) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(
      `${j} ${translate('common.by')} ${pages}`,
      horizontalPos,
      verticalPos,
      {
        align: 'center',
      }
    );

    const currentDate = new Date();
    const createdAt = `${currentDate.toLocaleString(defaultLocaleString)}`;

    doc.text(createdAt, 10, verticalPos, {
      align: 'left',
    });
  }

  await doc.save(pdfName);
};

export const generateProjectMilestoneReportEXCEL = async (
  type: ReportType,
  options: ReportOptions
) => {
  const { getCalculation, buildProjectCalculation } = useCalculation();
  const { checkAndbuildSheetName } = useReport();

  if (options.Calculations.length === 0) return;

  const wb = utils.book_new();
  const xlsxName = createReportName(type, ReportFileType.XLSX, true);

  for (const calc of options.Calculations) {
    let projectSumSelfCost = 0;
    let projectSumhours = 0;
    if (calc.Id) {
      const calculation = await getCalculation(calc.Id);

      if (calculation) {
        const projectCalc = await buildProjectCalculation(calculation);
        if (!projectCalc) continue;

        const sheetName = checkAndbuildSheetName(
          projectCalc.Name,
          wb.SheetNames
        );

        wb.Props = {
          Title: `${translate('reports.project-milestone-report')}`,
          CreatedDate: new Date(),
        };

        wb.SheetNames.push(sheetName);

        const milestones = getMileStoneData(projectCalc, false);
        const withoutMilestone = milestones.get(NO_MILESTONE);
        const mileStonedata: (string | number)[][] = [];

        mileStonedata.push([sheetName]);
        mileStonedata.push(['']);

        await createHeaderXlsx(mileStonedata);
        await createProjectInfoXlsx(mileStonedata);

        if (milestones) {
          if (withoutMilestone) {
            const mileStonedata: (string | number)[][] = [];

            mileStonedata.push(['']);
            mileStonedata.push([`${translate(`labels.${NO_MILESTONE}`)}`]);
            mileStonedata.push(['']);
            mileStonedata.push([
              `${translate('labels.milestone')}`,
              `${translate('labels.building-element')}`,
              `${translate('labels.building-item')}`,
              `${translate('labels.unit')}`,
              `${translate('labels.amount')}`,
              `${translate('labels.sum-selfcost-mat-sub')}`,
              `${translate('labels.hours')}`,
            ]);

            withoutMilestone.forEach(item => {
              const row = [
                item.MileStone ?? '',
                item.BuildingElement?.Name ?? '',
                item.ItemName ?? '',
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors,
                  2
                ),
                numberToAbsStringOrEmpty(item.SumHours, 2),
              ];

              mileStonedata.push(row);
            });

            projectSumSelfCost += withoutMilestone.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
            }, 0);
            projectSumhours += withoutMilestone.reduce((prev, cur) => {
              return prev + (cur.SumHours ?? 0);
            }, 0);

            const lastRow = [
              '',
              '',
              '',
              '',
              '',
              numberToAbsStringOrEmpty(
                withoutMilestone.reduce((prev, cur) => {
                  return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
                }, 0),
                2
              ),
              numberToAbsStringOrEmpty(
                withoutMilestone.reduce((prev, cur) => {
                  return prev + (cur.SumHours ?? 0);
                }, 0),
                2
              ),
            ];

            mileStonedata.push(lastRow);
            milestones.delete(NO_MILESTONE);
          }

          milestones.forEach((value, key) => {
            mileStonedata.push(['']);
            mileStonedata.push([
              `${translate('labels.milestone')}`,
              `${translate('labels.building-element')}`,
              `${translate('labels.building-item')}`,
              `${translate('labels.unit')}`,
              `${translate('labels.amount')}`,
              `${translate('labels.sum-selfcost-mat-sub')}`,
              `${translate('labels.hours')}`,
            ]);
            mileStonedata.push(['']);
            value.forEach(item => {
              const row = [
                item.MileStone ?? '',
                item.BuildingElement?.Name ?? '',
                item.ItemName ?? '',
                item.Unit ?? '',
                numberToAbsStringOrEmpty(item.Amount, 2),
                numberToAbsStringOrEmpty(
                  item.SumSelfCostMaterialsSubContractors,
                  2
                ),
                numberToAbsStringOrEmpty(item.SumHours, 2),
              ];

              mileStonedata.push(row);
            });

            projectSumSelfCost += value.reduce((prev, cur) => {
              return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
            }, 0);
            projectSumhours += value.reduce((prev, cur) => {
              return prev + (cur.SumHours ?? 0);
            }, 0);

            const lastRow = [
              key,
              '',
              '',
              '',
              '',
              numberToAbsStringOrEmpty(
                value.reduce((prev, cur) => {
                  return prev + (cur.SumSelfCostMaterialsSubContractors ?? 0);
                }, 0),
                2
              ),
              numberToAbsStringOrEmpty(
                value.reduce((prev, cur) => {
                  return prev + (cur.SumHours ?? 0);
                }, 0),
                2
              ),
            ];

            mileStonedata.push(lastRow);
          });
          mileStonedata.push(['']);
          mileStonedata.push([
            `${translate('labels.total')}`,
            '',
            '',
            '',
            '',
            numberToAbsStringOrEmpty(projectSumSelfCost, 2),
            numberToAbsStringOrEmpty(projectSumhours, 2),
          ]);

          const ws = utils.aoa_to_sheet(mileStonedata);
          wb.Sheets[sheetName] = ws;
        }
      }
    }
  }
  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
  downloadExcel(xlsxName, wbout);
};

export const generateProjectMilestoneReport = async (
  type: ReportType,
  options: ReportOptions
) => {
  switch (options.FileType) {
    case ReportFileType.PDF:
      await generateProjectMilestoneReportPDF(type, options);
      break;
    case ReportFileType.XLSX:
      await generateProjectMilestoneReportEXCEL(type, options);
      break;
    default:
      break;
  }
};
